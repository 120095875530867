import React from 'react';
import styles from '../css-modules/Footer.module.css';

/**
 * Footer - the footer component
 */
export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.contentContainer}>
        <p>5050 Soquel Dr | Soquel, California | 95073</p>
        <p>Sun - Thurs: 11am - 9:30pm | Fri & Sat: 11am - 10pm</p>
        <hr width="75%"/>
        <p>Copyright © 2013 Sawasdee Thai Cuisine. All rights reserved</p>
      </div>
    </footer>
  )
}
