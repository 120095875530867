import React from 'react';
import styles from '../css-modules/Body.module.css';

const StoreInfo = () => (
  <div className={styles.storeInfoContainer}>
    <div className={styles.innerInfo}>
      <h3>Location</h3>
      <p>
        5050 Soquel Dr, <br/>
        Soquel, California <br/>
        95073
      </p>

      <h3>Phone</h3>
      <p>
        <a href="tel:+1-831-462-5051">(831) 462-5051</a> <br/>
        <a href="tel:+1-831-431-6988">(831) 431-6988</a>
      </p>

      <h3>Hours</h3>
      <p>
        Sunday to Thursday: <br/>
        11am - 9:30pm <br/>
        Friday & Saturday: <br/>
        11am - 10pm
      </p>

      <h3>Email</h3>
      <a href="mailto:sawasdee5050@gmail.com">sawasdee5050@gmail.com</a>
    </div>
    <div className={styles.mapContainer}>
      <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=5050%20SOQUEL%20DR&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
    </div>
  </div>
);

export default StoreInfo;
