import React from 'react';

// Global style
import './global.css'

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';

export default ({ children }) => (
  <>
    <Header />
    <main className="mainContainer" id="mainContent">
      <div className="contentContainer">
        {children}
      </div>
    </main>
    <Footer />
  </>
);
