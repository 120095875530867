import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styles from '../css-modules/Header.module.css';

/**
 * Header component
 */
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false
    }

    this.handleStateChange = this.handleStateChange.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  /**
   * handleStateChange - binds the state of the header component to the state of the side menu
   * this function will be called when the state is changed (onStateChange)
   * @param  {boolean} state the state of the side menu
   */
  handleStateChange(state) {
    this.setState({menuIsOpen: state.isOpen});
  }

  /**
   * closeMenu - sets the state of the menu to false
   */
  closeMenu() {
    this.setState({menuIsOpen: false});
  }

  render() {
    return (
      <header className={styles.header} id="header">
        <div className={styles.navContainer}>
          <AniLink className={styles.homeLink} fade to='/' duration={1}>
            <h1 className={`${styles.headerTitle} ${styles.desktopH1}`}>Sawasdee Thai Cuisine</h1>
            <h1 className={`${styles.headerTitle} ${styles.mobileH1}`}>Sawasdee</h1>
          </AniLink>
          <NavigationList
            menuIsOpen={this.state.menuIsOpen}
            handleStateChange={this.handleStateChange}
            closeMenu={this.closeMenu}
          />
        </div>
      </header>
    );
  }
}


/**
 * NavigationList - renders the DesktopNavigation and MobileNavigation components - passes navlinks as props
 * @param  {boolean} props.menuIsOpen     the state of the side menu
 * @param  {function} props.onStateChange function to pass to burger-menu
 * @param  {function} props.closeMenu     close the burger menu
 */
function NavigationList(props) {
  const mainNavs = ['Home', 'Lunch Menu', 'Dinner Menu', 'About'];
  const orderNav = 'Order Online';

  return (
    <>
      <DesktopNavigation navigationAnchors={mainNavs} externalLink={orderNav}/>
      <MobileNavigation
        navigationAnchors={mainNavs}
        externalLink={orderNav}
        menuIsOpen={props.menuIsOpen}
        handleStateChange={props.handleStateChange}
        closeMenu={props.closeMenu}
      />
    </>
  )
}


/**
 * DesktopNavigation - navigation bar on desktop displays
 *
 * @param  {string[]} props.navigationAnchors a list of navigation links
 * @param  {string}   externalLink external links to render
 */
function DesktopNavigation(props) {
  // Map list of navigation links
  const navigationList = props.navigationAnchors.map(url => {
    let route = url === 'Home' ? '' : url.toLowerCase().replace(' ', '-');
    return (
      <li key={url} className={styles.navItem}>
        <AniLink fade
          to={`/${route}`}
          className={styles.navLink}
          activeClassName={styles.active}
          duration={1}
        >
          {url}
        </AniLink>
      </li>
    )
  });

  navigationList.push((
    <li key={props.externalLink} className={styles.navItem}>
      <OrderButton text="Order Online"/>
    </li>
  ));

  return (
    <ul className={styles.desktopNav}>
      {navigationList}
    </ul>
  )
}

function OrderButton(props) {
  return (
    <a
      href="https://onlineorders.wawio.com/menu/58b094014f5ee90b787b23ce"
      id={styles.orderBtn}
      className={styles.navLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => confirmLocation()}
    >{props.text}
    </a>
  )
}

/**
 * MobileNavigation - mobile burger menu navigation
 *
 * @param  {string[]} props.navigationAnchors a list of navigation links
 * @param  {string}   externalLink external links to render
 * @param  {boolean} props.menuIsOpen     the state of the side menu
 * @param  {function} props.onStateChange function to pass to burger-menu
 * @param  {function} props.closeMenu     close the burger menu
 *
 */
function MobileNavigation(props) {
  const navigationList = props.navigationAnchors.map(url => {
    let route = url === 'Home' ? '' : url.toLowerCase().replace(' ', '-')
    return (
      <AniLink fade
        key={url}
        className={styles.mobileNavItem}
        to={`/${route}`}
        activeClassName={styles.active}
        onClick={() => props.closeMenu()}
        duration={1}
      >
        <div className={styles.linkContainer}>{url}</div>
      </AniLink>
    )
  });

  navigationList.push(
    <a
      key={props.externalLink}
      href="https://onlineorders.wawio.com/menu/58b094014f5ee90b787b23ce"
      className={styles.mobileNavItem}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {props.closeMenu(); confirmLocation();}}
    > <button>Order Online</button>
    </a>
  );
  navigationList.push(<hr key="hr"/>);

  return (
    <>
      <div className={styles.mobileOrderButton}>
        <OrderButton text="Order Now"/>
      </div>
      <div className={styles.bmContainer}>
        <Menu
          right
          width={'50%'}
          onStateChange={(state) => props.handleStateChange(state)}
          isOpen={props.menuIsOpen}
        >
          <h2>Navigation</h2>
          <hr/>
          {navigationList}
        </Menu>
      </div>
    </>
  )
}

/**
 * confirmLocation - a prompt to confirm location before opening external link to online ordering
 */
function confirmLocation() {
  window.confirm('! Pickup Location is 5050 Soquel Dr !\n\nOnline Ordering only offered at Soquel Location currently')
}

export default Header;
